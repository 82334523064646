import { FC } from 'react';
import { useUserIsLogged } from '@common/selectors/user.selectors';
import { WelcomeSectionLoggedIn } from './WelcomeSectionLoggedIn';
import { WelcomeSectionLoggedOut } from './WelcomeSectionLoggedOut';

export const WelcomeSection: FC = () => {
	const isLogged = useUserIsLogged();

	if (isLogged) {
		return <WelcomeSectionLoggedIn />;
	}

	return <WelcomeSectionLoggedOut />;
};
